import React from "react"
import Layout from "../components/layout"
import bg from "../images/backgrounds/detail-bg.png"
import Row from "../components/row"
import text from "../data/nl"
import SEO from "../components/seo"
import { Link } from "gatsby"

const detailPage = () => (
  <Layout bigfoot={false}>
    <SEO title={text.solutions.meta.title} description={text.solutions.meta.description}/>
    <Row image={bg} size="small" classes=" pt-200 pb-60" id="OplossingenBusinessUnits">
      <div className="column col-12">
        <h1 className="t-50 uppercase bold mb-30">{text.solutions.title}</h1>
        <p dangerouslySetInnerHTML={{ __html: text.solutions.description }} />
      </div>
      <div className="hbox column col-12">
        {text.solutions.businessUnits.map((unit) =>
          <div className="relative p-50 col-6"  key={unit.title}>
            <div className="f-container">
              <img src={unit.image} className="mw-120 white-text f-center" alt=""/>
              <div className="ml-60 mr-30">
                <h3 className="t-17 bold uppercase mb-20">{unit.title}</h3>
                <p className="align-justify lh-150 hyphen-manual" dangerouslySetInnerHTML={{__html: unit.description }} />
              </div>
              <Link to={unit.ref} className="overlay-link"/>
            </div>
          </div>
        )}
      </div>
    </Row>
    <Row classes="grey pt-60 pb-60" size="small" id="OplossingenOplossingen">
      <div className="hbox">
        {text.solutions.businessUnits.map((unit) =>
          <div className="col-4 column" key={"2"+unit.title}>
            {unit.solutions.map(solution =>
              <div className="h-300 relative" key={solution.alt}>
                <div className="h-60 mb-30">
                  <img src={solution.image} alt={solution.alt} className="max-width-100 max-height-100"  style={{height: solution.imageHeight != null ? solution.imageHeight : "auto" }} />
                </div>
                <p>{solution.description}</p>
                <Link to={solution.ref} className="overlay-link"/>
              </div>
            )}
          </div>
        )}
      </div>
    </Row>
    <Row classes="pt-60" size="small" id="OplossingenProducten">
      <div className="column col-12">
        <h2 className="t-50 uppercase bold">{text.solutions.products.title}</h2>
        <p dangerouslySetInnerHTML={{ __html: text.solutions.products.description }}/>
      </div>
      <div className="hbox mt-30">
      {text.solutions.products.products.map(product =>
        <div className="col-4 column mt-30" key={"2"+product.alt}>
            <div className="relative">
              <div className="h-60 mb-30">
                <img src={product.image} alt={product.alt} className="mb-30 max-width-100 max-height-100" />
              </div>
              <p>{product.description}</p>
              <Link to={product.ref} className="overlay-link"/>
            </div>
        </div>
      )}
      </div>
    </Row>
  </Layout>
)

export default detailPage;
